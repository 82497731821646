// Vendor
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

// Components
import autoInit from 'Components/auto-init';

// CSS
import './style.scss';

autoInit.register('.js--section-events-slider', (el) => {
    let $el = $(el);

    $el.slick({
        fade: true,
        arrows: false,
        speed: 300,
    });

    $el.on('click', '.js--section-events-slider__thumb', (e) => {
        $el.slick('slickGoTo', $(e.currentTarget).data('slide'));
    });
});
