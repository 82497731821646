// Vendor
import lightbox from 'lightbox2/dist/js/lightbox.min';
import 'lightbox2/dist/css/lightbox.min.css';

// CSS
import './style.scss';

$(() => {
    lightbox.option({
        albumLabel: '',
        fadeDuration: 400,
        imageFadeDuration: 400,
        showImageNumberLabel: false,
        disableScrolling: true,
        resizeDuration: 400,
        wrapAround: true,
        positionFromTop: 80,
    });

    window.lightbox = lightbox;
});
