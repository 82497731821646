$(() => {
    $(document).on('click touchend', '.js--anchor-link', (e) => {
        e.preventDefault();
        let elementClick = $(e.currentTarget).attr('href');
        let destination = $(elementClick).offset().top;
        $('html:not(:animated),body:not(:animated)').animate({
            scrollTop: destination,
        }, 800);
        $(document).trigger('anchorLink:scroll');
    });
});
