// Components
import 'Components/menu';
import autoInit from 'Components/auto-init';

// CSS
import './style.scss';

/**
 * @class SiteMenu
 */
class SiteMenu {
    /**
     * @constructor
     * @param {HTMLElement} el
     */
    constructor(el) {
        this.active = false;
        this.$el = $(el);
        this.$btnOpen = this.$el.find('.js--site-menu__btn-open');
        this.$btnClose = this.$el.find('.js--site-menu__btn-close');
        this.$overlay = this.$el.find('.site-menu__overlay');
        this.$panel = this.$el.find('.site-menu__wrapper');
        this.cssClassActive = 'site-menu_mobile-menu-active';
        this.transitionDuration = parseFloat(this.$panel.css('transition-duration')) * 1000;

        this.setEventListeners();
    }

    /**
     * Назначение обработчиков событий
     */
    setEventListeners() {
        this.$btnOpen.on('click', () => {
            this.open();
        });

        this.$btnClose.on('click', () => {
            this.close();
        });

        // Закрывать меню при переходе по якорной ссылке
        $(document).on('anchorLink:scroll', () => {
            if (this.active) {
                this.close();
            }
        });

        // Закрывать меню при изменении ориентации планшета
        $(document).on('orientationchange', () => {
            if (this.active) {
                this.close();
            }
        });
    }

    /**
     * Открыть меню
     */
    open() {
        this.$el.addClass(this.cssClassActive);

        // Закрывать меню при клике вне
        $(document).on('click.siteMenu touchend.siteMenu', (e) => {
            let $el = this.$panel.add(this.$btnOpen);
            if (!$el.is(e.target) && $el.has(e.target).length === 0) {
                this.close();
            }
        });

        document.body.style.overflow = 'hidden';
        this.active = true;
    }

    /**
     * Закрыть меню
     */
    close() {
        this.$overlay[0].style.opacity = 1;
        setTimeout(() => {
            this.$overlay[0].style.opacity = '';
        }, this.transitionDuration);
        this.$el.removeClass(this.cssClassActive);
        $(document).off('click.siteMenu touchend.siteMenu');
        document.body.style.overflow = '';
        this.active = false;
    }
}

autoInit.register('.js--site-menu', (el) => {
    new SiteMenu(el);
});
