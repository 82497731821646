let handlers = [];
let observer;

/**
 * @param {HTMLElement} node
 * @param {String} selector
 * @param {Function} handler
 */
function nodeInit(node, selector, handler) {
    const _autoInit = '_autoInit';
    if (!node[_autoInit] || !node[_autoInit][selector]) {
        handler(node);
        node[_autoInit] = node[_autoInit] || {};
        node[_autoInit][selector] = true;
    }
}

/**
 * @param {Document|HTMLElement} node
 */
function processHandlers(node) {
    handlers.forEach((item) => {
        let [selector, handler] = item;

        const matches = node.matches || node.matchesSelector || node.webkitMatchesSelector || node.mozMatchesSelector
            || node.msMatchesSelector || node.oMatchesSelector;

        if (matches && matches.call(node, selector)) {
            nodeInit(node, selector, handler);
        }

        node.querySelectorAll(selector).forEach((element) => {
            nodeInit(element, selector, handler);
        });
    });
}

/**
 * @param {[]} mutations
 */
function processMutations(mutations) {
    mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
            if (node.nodeType === Node.ELEMENT_NODE) {
                processHandlers(node);
            }
        });
    });
}

export default {
    register: (selector, handler) => {
        handlers.push([selector, handler]);
    },

    start: () => {
        processHandlers(document);
        observer = new MutationObserver(processMutations);
        observer.observe(document, {
            childList: true,
            subtree: true,
        });
    },

    stop: () => {
        observer.disconnect();
    },
};
