// Components
import autoInit from 'Components/auto-init';

// CSS
import './style.scss';

/**
 * @class Countdown
 */
class Countdown {
    /**
     * @constructoe
     * @param {HTMLElement} el
     * @param {Number} endDate - UNIX-time
     */
    constructor(el, endDate) {
        this.$el = $(el);
        this.$d = this.$el.find('.js--countdown__d');
        this.$h = this.$el.find('.js--countdown__h');
        this.$m = this.$el.find('.js--countdown__m');
        this.$s = this.$el.find('.js--countdown__s');
        this.endDate = endDate;
        this.interval = false;

        this.run();
    }

    /**
     * Запустить таймер
     */
    run() {
        this.interval = setInterval(() => {
            let now = new Date().getTime();

            let d;
            let h;
            let m;
            let s;
            let distance;

            if (now > this.endDate) {
                d = 0;
                h = 0;
                m = 0;
                s = 0;
            } else {
                distance = this.endDate - now;
                d = Math.floor(distance / (1000 * 60 * 60 * 24));
                h = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                m = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                s = Math.floor((distance % (1000 * 60)) / 1000);
            }

            this.$d.text(d);
            this.$h.text(h);
            this.$m.text(m);
            this.$s.text(s);
        }, 1000);
    }
}

let curYear = (new Date()).getFullYear();
let endDate = (new Date((curYear + 1), 0, 1)).getTime();

autoInit.register('.js--countdown', (el) => {
    new Countdown(el, endDate);
});
