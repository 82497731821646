// Vendor
import 'slick-carousel';
import 'slick-carousel/slick/slick.css';

// Components
import autoInit from 'Components/auto-init';

// CSS
import './style.scss';

autoInit.register('.js--slider', (el) => {
    let $el = $(el);
    let options = $el.data('slider');

    $el.slick(options);
});
