// Vendors
import IMask from 'imask';

// Components
import autoInit from 'Components/auto-init';

const maskOptions = {
    mask: [
        {
            mask: '+{7} (000) 000-00-00',
            startsWith: '7',
        },
        {
            mask: '+{3}0 (000) 000-00-00',
            startsWith: '3',
        },
        {
            mask: '+{7} (000) 000-00-00',
            startsWith: '',
        },
    ],
    lazy: false,
    dispatch(appended, dynamicMasked) {
        let number = (dynamicMasked.value + appended).replace(/\D/g, '');
        return dynamicMasked.compiledMasks.find((m) => number.indexOf(m.startsWith) === 0);
    },
};

autoInit.register('.js--inputmask-phone', (el) => {
    new IMask(el, maskOptions);
});
