// Vendor
import OnScreen from 'onscreen';

// CSS
import './style.scss';

$(() => {
    if ($('html').hasClass('no-touchevents')) {
        const os = new OnScreen();

        os.on('enter', '.section-on-screen', (el) => {
            $(el).addClass('section-on-screen_active');
        });
    }
});
