// Common components
import 'Components/common';

// Layouts
import 'Layouts/main';

// Components
import 'Components/section-top';
import 'Components/section-countdown-1';
import 'Components/section-advantages';
import 'Components/section-video';
import 'Components/section-events';
import 'Components/section-programs';
import 'Components/section-prices';
import 'Components/section-countdown-2';
import 'Components/section-faq';
import 'Components/section-actors';
import 'Components/section-gallery';
import 'Components/section-why';
import 'Components/section-feedback';
import 'Components/section-bottom';

// CSS
import './style.scss';
