// Vendor
import 'modernizr';

// Polyfills
import 'nodelist-foreach-polyfill';

// Components
import 'Components/container';
import 'Components/hidden';
import 'Components/anchor-link';
import 'Components/section-on-screen';
import autoInit from 'Components/auto-init';

// CSS
import './style.scss';

$(() => {
    autoInit.start();
});
