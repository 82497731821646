// Vendor
import MicroModal from 'micromodal';

// CSS
import './style.scss';

$(() => {
    MicroModal.init({
        disableScroll: true,
        awaitCloseAnimation: true,
        onClose() {
            // IE Edge fix
            document.body.style.overflow = '';
        },
    });
});
